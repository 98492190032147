import Vue from 'vue'
import App from './App.vue'
import element from 'element-ui';
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
// import store from "./utils/store";
Vue.use(element);
import { Buffer } from 'buffer';
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

window.Buffer = Buffer; // 将 Buffer 挂载到 window 对象，以便 TronWeb 可以使用它

Vue.config.productionTip = false
new Vue({
  // store,
  render: h => h(App),
  router
}).$mount('#app')
