<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>


export default {
  name: 'App',
  data() {
    return {
      server: '',
    };
  },
  mounted() {
    // this.getConfig()
    document.addEventListener('gesturestart', this.preventZoom);
    document.addEventListener('gesturechange', this.preventZoom);
    document.addEventListener('gestureend', this.preventZoom);
  },
  beforeDestroy() {
    document.removeEventListener('gesturestart', this.preventZoom);
    document.removeEventListener('gesturechange', this.preventZoom);
    document.removeEventListener('gestureend', this.preventZoom);
  },
  methods: {
    preventZoom(event) {
      event.preventDefault();
    },


  }
};
</script>

<style>


html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: clamp(12px, 4vw, 16px); /* 设置根字体大小 */
}

body {
  background: #f7f7f7;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flex-c {
  display: flex;
  flex-direction: column;
}

.flex-h {
  display: flex;
  flex-direction: row;
}

.flex-c-center {
  justify-content: center;
}

.flex-h-center {
  align-items: center;
}

.text-title {
  font-family: PingFang SC;
  font-size: 1.13rem;
  font-weight: 600;
  color: #1D2129;
}

.text-gray {
  font-family: PingFang SC;
  font-size: 0.88rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  /* 主色/深灰 */
  color: #6B7373;
}

.flex-1 {
  flex: 1;
}

.flex-sb {
  justify-content: space-between
}

.oneline {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nuxt-link {
  text-decoration: none;
}

/* 全局样式文件 */
a {
  text-decoration: none;
}


</style>
